<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedPointId"
                    :items="availablePoints"
                    item-text="name"
                    item-value="id"
                    :label="$t(`common['Select Point Setting']`)"
                    :disabled="isActionDisabled"
                    @change="getPointPeriods"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedPeriodId"
                    :items="availablePeriods"
                    item-text="name"
                    item-value="id"
                    :label="$t(`common['Select Period']`)"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="isActionDisabled"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedPeriodId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedBudgetId"
                    :items="availableBudgets"
                    item-text="budgetName"
                    item-value="id"
                    label="Select Annual Budgets"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="isActionDisabled"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedBudgetId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{$t(`common['Nothing to select']`)}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col> -->
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedOrganizationId"
                    :items="availableOrganizations"
                    item-text="organizationName"
                    item-value="organizationId"
                    :label="$t(`common['Select Organization']`)"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="
                      !permissionScope.organization || isActionDisabled
                    "
                    @change="getDepartmentList"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          selectedOrganizationId = null;
                          selectedDepartmentId = null;
                          dAvailableDepartments = [];
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedDepartmentId"
                    :label="$t(`budgets['Department']`)"
                    :items="dAvailableDepartments"
                    item-text="departmentName"
                    item-value="id"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="!permissionScope.department || isActionDisabled"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedDepartmentId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="getDepartmentBudgets"
                      :disable="!isActionDisabled"
                      :loading="!isBudgetsLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              單位預算列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isBudgetsLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:item.allocatedPoints="{ item }">
                  <span>{{ formatNumber(item.allocatedPoints, 0) }}</span>
                  <!-- <div class="text-right">
                    {{ formatNumber(item.allocatedPoints, 0) }}
                  </div> -->
                </template>
                <template v-slot:item.availablePoints="{ item }">
                  <span>{{ formatNumber(item.availablePoints, 0) }}</span>
                </template>
                <template v-slot:item.usedPoints="{ item }">
                  <span>{{ formatNumber(item.usedPoints, 0) }}</span>
                </template>
                <template v-slot:item.returnPoints="{ item }">
                  <span>{{ formatNumber(item.returnPoints, 0) }}</span>
                </template>
                <template v-slot:item.actualPoints="{ item }">
                  <span>{{ formatNumber(item.actualPoints, 0) }}</span>
                </template>
                <!-- <template v-slot:item.actualPoints="props">
                  <span>{{
                    formatNumber(
                      props.item.allocatedPoints +
                        props.item.transferInPoints -
                        props.item.returnPoints,
                      0
                    )
                  }}</span>
                </template> -->
                <template v-slot:item.reviewStatus="{ item }">
                  <span v-if="item.reviewStatus === 0"> 審核中 </span>
                  <span v-else-if="item.reviewStatus === 1"> 核准 </span>
                  <span v-else> 退回 </span>

                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="teal"
                        v-on="on"
                        :disabled="item.reviewStatus !== 0"
                        v-if="item.reviewStatus === 0"
                        @click="onApproveBudgetClick(item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>審核預算</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.executionRate="props">
                  <span
                    v-if="
                      props.item.allocatedPoints +
                        props.item.transferInPoints -
                        props.item.returnPoints ===
                      0
                    "
                  >
                    0.00%
                  </span>
                  <span v-else>{{
                    (
                      (props.item.usedPoints /
                        (props.item.allocatedPoints +
                          props.item.transferInPoints -
                          props.item.returnPoints)) *
                      100
                    ).toFixed(2) + "%"
                  }}</span>
                </template>
                <template v-slot:item.startTs="{ item }">
                  <span>{{
                    item.startTs
                      ? moment.unix(item.startTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.endTs="{ item }">
                  <span>{{
                    item.endTs
                      ? moment.unix(item.endTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>

                <template v-slot:item.actions="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="gotoBudgetsPage(props.item)"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看單位活動預算</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.reviewStatus === 0 ? 'cyan' : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.reviewStatus === 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.reviewStatus === 0
                            ? onEditItem(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.availablePoints > 0
                            ? 'blue-grey darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.availablePoints > 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.availablePoints > 0
                            ? onReturnItemClick(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-arrow-left</v-icon>
                      </v-btn>
                    </template>
                    <span>回收</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="pink darken-2"
                        v-on="on"
                        @click="
                          selectedBudgetId = props.item.id;
                          openDetailDialog = true;
                        "
                      >
                        <v-icon>fas fa-bars</v-icon>
                      </v-btn>
                    </template>
                    <span>查看收支明細</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.reviewStatus === 1 &&
                          props.item.closeStatus === 0
                            ? 'purple darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.reviewStatus === 1 &&
                            props.item.closeStatus === 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.reviewStatus === 1 &&
                          props.item.closeStatus === 0
                            ? onBudgetTransferClick(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-exchange-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>預算轉讓</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="!isBudgetsLoaded"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`common["Budget"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.pointId"
                      :label="$t(`points['Name']`)"
                      :items="availablePoints"
                      item-text="name"
                      item-value="id"
                      :rules="rules.required"
                      @change="onEditItemChange($event, 'pointId')"
                      :no-data-text="$t(`common['No Data']`)"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.periodId"
                      :items="availableUnclosingPeriods"
                      item-text="name"
                      item-value="id"
                      :label="$t(`common['Select Period']`)"
                      :menu-props="{ closeOnContentClick: true }"
                      :disabled="isActionDisabled"
                      @change="onEditItemChange($event, 'periodId')"
                      :no-data-text="$t(`common['No Data']`)"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.organizationId"
                      :label="$t(`budgets['Organization']`)"
                      :items="availableOrganizations"
                      item-text="organizationName"
                      item-value="organizationId"
                      :rules="rules.required"
                      @change="onEditItemChange($event, 'organizationId')"
                      :no-data-text="$t(`common['No Data']`)"
                      :disabled="
                        !permissionScope.organization || isActionDisabled
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.annualBudgetId"
                      :label="$t(`budgets['AnnualBudget']`)"
                      :items="dAvailableBudgets"
                      item-text="budgetName"
                      item-value="id"
                      :rules="rules.required"
                      :no-data-text="$t(`common['No Data']`)"
                    >
                      <template slot="selection" slot-scope="data">
                        <!-- HTML that describe how select should render selected items -->
                        {{ data.item.budgetName }} （{{
                          formatNumber(data.item.availablePoints, 0)
                        }}）
                      </template>
                      <template slot="item" slot-scope="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        {{ data.item.budgetName }} （{{
                          formatNumber(data.item.availablePoints, 0)
                        }}）
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.departmentId"
                      :label="$t(`budgets['Department']`)"
                      :items="dAvailableDepartments"
                      item-text="departmentName"
                      item-value="id"
                      :rules="rules.required"
                      required
                      :no-data-text="$t(`common['No Data']`)"
                      :disabled="!permissionScope.department"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.name"
                      :label="$t(`budgets['Name']`)"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.allocatedPoints"
                      type="number"
                      :label="$t(`budgets['AllocatedPoints']`)"
                      :rules="rules.budgets"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editItem.description"
                      outlined
                      :label="$t(`budgets['ApplicationDescription']`)"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditItem">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openApproveDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">審核預算</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="approveForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="reviewStatus"
                      row
                      mandatory
                      class="large-radio"
                    >
                      <v-radio
                        label="核准"
                        :value="1"
                        :color="reviewStatus === 1 ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="退回"
                        :value="2"
                        :color="reviewStatus === 2 ? '#1867c0' : ''"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="簽核意見"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="blue darken-1" text @click="uploadImage">Upload</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeApproveDialog">
              {{ $t(`common['Cancel']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onApproveBudget()">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openDetailDialog" max-width="800px" fullscreen>
        <Ledger
          v-if="openDetailDialog"
          entity-type="DB"
          :budget-id="selectedBudgetId"
          @close="openDetailDialog = false"
        ></Ledger>
      </v-dialog>
      <v-dialog v-model="openTransferDialog" max-width="600px">
        <BudgetTransfer
          v-if="openTransferDialog"
          entity-type="DepartmentBudget"
          :giver="transferFrom"
          :available-transfer-budgets="availableTransferBudgets"
          @close="onCloseTransferDialog"
          @done="onTransferredDone"
        ></BudgetTransfer>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchPointSettings,
  fetchPointPeriods,
  fetchAnnualBudgets,
  fetchDepartmentBudgets,
  fetchDepartments,
  fetchOrganizations,
} from "src/apis/fetchData";
import {
  updateDepartmentBudget,
  approveDepartmentBudget,
  returnDepartmentBudget,
} from "src/apis/updateData";

import {
  generateArrayOfYears,
  generateArrayOfDays,
  generateArrayOfMonths,
} from "src/util/utils";
import Ledger from "./Widgets/Ledger.vue";
import { formatNumber } from "src/util/utils";
import BudgetTransfer from "./Widgets/BudgetTransfer.vue";
import { checkPermission } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  components: { Ledger, BudgetTransfer, Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      isPeriodsLoaded: true,
      isAnnualBudgetsLoaded: true,
      isBudgetsLoaded: true,
      isNew: false,
      openEditDialog: false,
      openApproveDialog: false,
      availableOrganizations: [],
      selectedOrganizationId: null,
      availablePoints: [],
      selectedPointId: null,
      availableBudgets: [],
      // dAvailableBudgets: [],
      selectedBudgetId: null,
      selectedDepartmentId: null,
      dAvailableDepartments: [],
      currentPointId: null,
      availablePeriods: [],
      selectedPeriodId: null,
      currentPeriodId: null,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t(`budgets["Department"]`),
          sortable: true,
          filterable: true,
          value: "departmentName",
        },
        {
          text: this.$t(`budgets["Name"]`),
          sortable: true,
          value: "budgetName",
        },
        {
          text: "原始預算",
          sortable: true,
          filterable: false,
          align: "right",
          value: "allocatedPoints",
        },
        {
          text: "實際預算",
          sortable: true,
          filterable: false,
          align: "right",
          value: "actualPoints",
        },
        {
          text: this.$t(`budgets["Execution Rate"]`),
          sortable: false,
          filterable: false,
          align: "right",
          value: "executionRate",
        },
        {
          text: "未使用",
          sortable: true,
          filterable: false,
          align: "right",
          value: "availablePoints",
        },
        {
          text: "已使用",
          sortable: true,
          filterable: false,
          align: "right",
          value: "usedPoints",
        },
        {
          text: "已回收",
          sortable: true,
          filterable: false,
          align: "right",
          value: "returnPoints",
        },
        {
          text: this.$t(`common["Status"]`),
          sortable: true,
          filterable: false,
          value: "reviewStatus",
        },
        {
          text: this.$t(`common["Start Date"]`),
          sortable: true,
          filterable: false,
          value: "startTs",
        },
        {
          text: this.$t(`common["End Date"]`),
          sortable: true,
          filterable: false,
          value: "endTs",
        },
        {
          text: this.$t(`common["Created At"]`),
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: this.$t(`common["Updated At"]`),
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      defaultEditItem: {
        name: null,
        periodId: null,
        pointId: null,
        organizationId: null,
        departmentId: null,
        annualBudgetId: null,
      },
      editItem: {
        name: null,
        pointId: null,
        periodId: null,
        organizationId: null,
        departmentId: null,
        annualBudgetId: null,
      },
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        name: [(v) => !!v || this.$t(`common["Required"]`)],
        budgets: [
          (v) => !!v || this.$t(`common["Required"]`),
          (v) => v >= 0 || this.$t(`budgets["MinBudgetAllocate"]`),
        ],
      },
      approveBudget: null, //審核的預算
      reviewStatus: null,
      comment: null,
      openDetailDialog: false,
      transferFrom: {
        transferPoints: null,
        name: null,
      },
      // transferredBudgetId: null,
      openTransferDialog: false,
      availableTransferBudgets: [],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return !(
        this.isPeriodsLoaded &&
        this.isAnnualBudgetsLoaded &&
        this.isBudgetsLoaded
      );
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    dAvailableBudgets() {
      let arrayBudget = [];

      if (this.openEditDialog) {
        if (
          this.editItem.periodId &&
          this.editItem.organizationId &&
          this.availableBudgets.length
        ) {
          arrayBudget = this.availableBudgets.filter(
            (item) =>
              item.reviewStatus === 1 &&
              item.organizationId === this.editItem.organizationId
          );
        }
      }
      return arrayBudget;
    },
    availableUnclosingPeriods() {
      return this.availablePeriods.filter((p) => p.closeStatus === 0);
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "EditDepartmentBudgets",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "AuditDepartmentBudgets",
          true
        );
      }
      return false;
    },
  },
  watch: {},
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
    // this.getAnnualBudgets();
    this.years = generateArrayOfYears();
    this.days = generateArrayOfDays();
    this.months = generateArrayOfMonths();
  },
  methods: {
    init() {
      this.getOrganizations();
      this.selectedOrganizationId = this.$route.query.organizationId
        ? parseInt(this.$route.query.organizationId)
        : this.organizationId;
      this.getPointSettings(true);
      if (!this.permissionScope.department) {
        this.selectedDepartmentId = this.departmentId;
      }
      this.getDepartmentList();
    },
    gotoBudgetsPage(item) {
      this.$router.push({
        name: "Campaign Budget",
        query: {
          pointId: this.currentPointId,
          periodId: item.periodId,
          parentId: item.id,
          departmentId: item.departmentId,
          organizationId: item.organizationId,
        },
      });
    },
    getOrganizations() {
      console.log("getOrganizations");
      fetchOrganizations(this.merchantId)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.availableOrganizations = [...res.data];
          console.log(
            "this.availableOrganizations:" +
              JSON.stringify(this.availableOrganizations)
          );
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        });
    },
    getPointSettings(search) {
      //const merchantId = 1;
      let matchRouteQuery = false;

      // console.log("before ", this.pointSettings);
      if (this.pointSettings && this.pointSettings.length) {
        this.availablePoints = [...this.pointSettings];
        if (
          this.availablePoints &&
          this.availablePoints.length &&
          this.$route.query.pointId
        ) {
          const result = this.availablePoints.filter(
            (point) => point.id === parseInt(this.$route.query.pointId)
          );
          matchRouteQuery = result.length ? true : false;
        }
        this.selectedPointId = this.availablePoints.length
          ? matchRouteQuery
            ? parseInt(this.$route.query.pointId)
            : this.availablePoints[0].id
          : null;
        this.$nextTick(
          this.getPointPeriods(null, this.selectedPointId, search)
        );
        return;
      }
      fetchPointSettings(this.merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          console.log(
            "this.availablePoints:" + JSON.stringify(this.availablePoints)
          );
          if (
            this.availablePoints &&
            this.availablePoints.length &&
            this.$route.query.pointId
          ) {
            const result = this.availablePoints.filter((point) => {
              return point.id === parseInt(this.$route.query.pointId);
            });
            matchRouteQuery = result.length ? true : false;
          }
          this.selectedPointId = this.availablePoints.length
            ? matchRouteQuery
              ? parseInt(this.$route.query.pointId)
              : this.availablePoints[0].id
            : null;
          this.$store.commit("setPointSettings", res.data);
          this.$nextTick(
            this.getPointPeriods(null, this.selectedPointId, search)
          );
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        });
    },
    getPointPeriods($event, pid, search) {
      // const merchantId = 1;
      console.log("getPointPeriods:" + this.selectedPointId);
      this.isPeriodsLoaded = false;
      const pointId = pid ? pid : this.selectedPointId;
      fetchPointPeriods(this.merchantId, pointId)
        .then((res) => {
          console.log("fetchPointPeriods done", res);
          this.availablePeriods = [...res.data];
          let matchRouteQuery = false;
          if (
            this.availablePeriods &&
            this.availablePeriods.length &&
            this.$route.query.periodId
          ) {
            const result = this.availablePeriods.filter(
              (item) => item.id === parseInt(this.$route.query.periodId)
            );
            matchRouteQuery = result.length ? true : false;
          }
          this.selectedPeriodId = this.availablePeriods.length
            ? matchRouteQuery
              ? parseInt(this.$route.query.periodId)
              : null
            : null;
          if (search) {
            this.$nextTick(
              // this.getAnnualBudgets(null, null, this.selectedPeriodId, search)
              this.getDepartmentBudgets()
            );
          }
        })
        .catch((e) => {
          console.log("fetchPointPeriods failed", e);
          this.$swal({
            text: "無法取得點數期別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isPeriodsLoaded = true;
          this.currentPointId = this.selectedPointId;
        });
    },
    getAnnualBudgets($event, inputPointId, inputPeriodId, search) {
      this.isAnnualBudgetsLoaded = false;
      const pointId = inputPointId ? inputPointId : this.selectedPointId;
      const periodId = inputPeriodId ? inputPeriodId : this.selectedPeriodId;
      let query = {
        periodId,
        // organizationId: this.selectedOrganizationId,
        // approved: dialog ? 1 : null,
      };
      console.log("getAnnualBudgets:" + JSON.stringify(query));

      console.log(
        "fetchAnnualBudgets query: " + JSON.stringify(query),
        pointId
      );
      fetchAnnualBudgets(this.merchantId, pointId, query)
        .then((res) => {
          console.log("fetchAnnualBudgets done", res);
          this.availableBudgets = [...res.data];
          // let matchRouteQuery = false;
          // if (
          //   this.availableBudgets &&
          //   this.availableBudgets.length &&
          //   this.$route.query.parentId
          // ) {
          //   const result = this.availableBudgets.filter(
          //     (item) => item.id === parseInt(this.$route.query.parentId)
          //   );
          //   matchRouteQuery = result.length ? true : false;
          // }
          // this.selectedBudgetId = this.availableBudgets.length
          //   ? matchRouteQuery
          //     ? parseInt(this.$route.query.parentId)
          //     : null
          //   : null;
          if (search) {
            this.$nextTick(this.getDepartmentBudgets());
          }
        })
        .catch((e) => {
          console.log("fetchAnnualBudgets failed", e);
          this.$swal({
            text: "無法取得期別預算資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isAnnualBudgetsLoaded = true;
          this.currentPointId = pointId;
          this.currentPeriodId = periodId;
        });
    },
    getDepartmentBudgets($event, inputPointId, inputPeriodId, inputBudgetId) {
      // const merchantId = 1;
      this.isBudgetsLoaded = false;
      this.pagination.page = 1;
      const pointId = inputPointId ? inputPointId : this.selectedPointId;
      const periodId = inputPeriodId ? inputPeriodId : this.selectedPeriodId;
      const annualBudgetId = inputBudgetId ? inputBudgetId : null;
      let query = {
        periodId,
        annualBudgetId,
        departmentId: this.selectedDepartmentId,
        organizationId: this.selectedOrganizationId,
      };
      console.log("getDepartmentBudgets:" + JSON.stringify(query));

      fetchDepartmentBudgets(this.merchantId, pointId, query)
        .then((res) => {
          console.log("fetchDepartmentBudgets done", res);
          this.tableData = res.data;
        })
        .catch((e) => {
          console.log("getDepartmentBudgets failed", e);
          this.$swal({
            text: "無法取得單位預算資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isBudgetsLoaded = true;
          this.currentPointId = pointId;
          this.currentPeriodId = periodId;
        });
    },
    getDepartmentList(inputOrganizationId) {
      let query = {
        organizationId: inputOrganizationId
          ? inputOrganizationId
          : this.selectedOrganizationId,
      };
      console.log("getDepartmentList:" + JSON.stringify(query));

      fetchDepartments(this.merchantId, query)
        .then((res) => {
          console.log("fetchDepartments done", res);
          this.dAvailableDepartments = [...res.data];
          // 當機關改成，選取的單位要清空重設
          if (this.permissionScope.department) {
            this.selectedDepartmentId = null;
          }
        })
        .catch((e) => {
          console.log("fetchDepartments failed", e);
          this.$swal({
            text: "無法取得單位資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.getDepartmentList();
      if (this.editItem.pointId !== this.selectedPointId) {
        this.getPointPeriods();
      }
      this.openEditDialog = false;
      this.editItem = Object.assign({}, this.defaultEditItem);
    },
    closeApproveDialog() {
      this.$refs.approveForm.resetValidation();
      this.$refs.approveForm.reset();
      this.openApproveDialog = false;
    },
    onApproveBudgetClick(item) {
      console.log("on approve click:" + JSON.stringify(item));
      this.approveBudget = item;
      this.openApproveDialog = true;
    },
    onApproveBudget() {
      console.log("approve budget:" + JSON.stringify(this.approveBudget));
      if (this.$refs.approveForm) {
        const valid = this.$refs.approveForm.validate();
        if (valid) {
          const data = {
            reviewer: this.ssoUser.userId,
            reviewStatus: this.reviewStatus,
            comment: this.comment,
          };
          console.log("approveDepartmentBudget data:" + JSON.stringify(data));
          approveDepartmentBudget(
            this.merchantId,
            this.departmentId,
            this.approveBudget.id,
            data
          )
            .then((res) => {
              console.log("approveDepartmentBudget done", res);
              this.approveBudget.reviewStatus = data.reviewStatus;
              this.approveBudget.comment = data.comment;
              if (data.reviewStatus === 1) {
                setTimeout(this.getDepartmentBudgets, 0);
              }
              this.comment = null;
              this.reviewStatus = null;
              this.openApproveDialog = false;
              this.$refs.approveForm.resetValidation();
              this.$refs.approveForm.reset();
            })
            .catch((e) => {
              console.log("approveDepartmentBudget failed", e.response);
              let errmsg = "無法更新單位預算審核結果";
              console.log();
              if (e.response && e.response.data && e.response.data.message) {
                errmsg += "<br/>" + e.response.data.message;
              }
              this.showErrorAlert(errmsg);
            });
        } else {
          this.openApproveDialog(this.$i18n.t(`errors["Incorrect Fields"]`));
        }
      }
    },
    onEditItem(item) {
      // // 已審核過的就不能再編輯
      // if (item.reviewStatus !== 0) {
      //   return;
      // }
      if (item) {
        this.editItem = Object.assign({}, item);
        this.editItem.name = item.budgetName;
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.editItem.pointId = this.currentPointId;
        this.editItem.periodId = this.currentPeriodId
          ? this.currentPeriodId
          : this.availablePeriods[0].id;
        this.editItem.organizationId = this.organizationId;
        if (!this.permissionScope.department) {
          this.editItem.departmentId = this.departmentId;
        }
        this.isNew = true;
      }
      // 取得最新的annual budget
      this.getAnnualBudgets(
        null,
        this.editItem.pointId,
        this.editItem.periodId,
        false
      );
      // if (this.dAvailableDepartments.length === 0) {
      //   this.getDepartmentList();
      // }
      if (
        this.editItem.organizationId !== this.selectedOrganizationId ||
        this.dAvailableDepartments.length === 0
      ) {
        this.getDepartmentList(this.editItem.organizationId);
      }
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        if (this.isNew) {
          this.editItem.applicantId = this.ssoUser.userId;
        }
        updateDepartmentBudget(
          this.merchantId,
          this.departmentId,
          this.editItem.pointId,
          this.editItem
        )
          .then((res) => {
            console.log("updateDepartmentBudget done", res);
            let self = this;
            this.selectedPointId = self.editItem.pointId;
            this.selectedPeriodId = self.editItem.periodId;
            this.selectedOrganizationId = self.editItem.organizationId;
            this.selectedDepartmentId = self.editItem.departmentId;
            setTimeout(function () {
              self.getDepartmentBudgets();
              self.openEditDialog = false;
              self.$refs.editForm.resetValidation();
              self.$refs.editForm.reset();
              this.editItem = Object.assign({}, this.defaultEditItem);
            }, 10);
          })
          .catch((e) => {
            console.log("updateDepartmentBudget failed", e);
            let errmsg = this.isNew ? "新增單位預算失敗" : "更新單位預算失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    // onReturnItemClick(item) {
    //   this.$swal({
    //     title: `回收單位預算`,
    //     html: `是否確定回收「<b>${item.budgetName}</b>」未使用的點數？
    //       <br/><br/> 回收點數：${item.availablePoints} 點`,
    //     type: "info",
    //     showCancelButton: true,
    //     confirmButtonText: this.$i18n.t(`common["Confirm"]`),
    //     cancelButtonText: this.$i18n.t(`common["Cancel"]`),
    //     customClass: {
    //       confirmButton: "btn bg-gradient-success",
    //       cancelButton: "btn bg-gradient-danger",
    //     },
    //   }).then((res) => {
    //     console.log(res);
    //     if (res.isConfirmed) {
    //       this.onReturnItem(item);
    //     }
    //   });
    // },
    // onReturnItem(item) {
    //   let data = {
    //     giverEntityType: "DepartmentBudget",
    //     giverEntityId: item.id,
    //     receiverEntityType: "AnnualBudget",
    //     receiverEntityId: item.annualBudgetId,
    //     points: item.availablePoints,
    //     note: "單位預算回收",
    //   };

    //   returnDepartmentBudget(this.merchantId, this.departmentId, item.id, data)
    //     .then((res) => {
    //       console.log("returnDepartmentBudget done: ", res);
    //       this.$swal({
    //         title: "回收成功",
    //         html: `已成功回收單位預算未使用點數<br/> 回收點數：${data.points}點`,
    //         type: "sccess",
    //         showCancelButton: false,
    //         confirmButtonText: this.$i18n.t(`common["Confirm"]`),
    //         customClass: {
    //           confirmButton: "btn bg-gradient-success",
    //           cancelButton: "btn bg-gradient-danger",
    //         },
    //       });
    //       item.availablePoints = 0;
    //       item.returnPoints = data.points;
    //     })
    //     .catch((e) => {
    //       console.log("returnDepartmentBudget failed", e);
    //       let errmsg = "無法回收單位預算未使用點數";
    //       if (e.response && e.response.data && e.response.data.message) {
    //         errmsg += "<br/>" + e.response.data.message;
    //       }
    //       const title = "回收失敗";
    //       this.showErrorAlert(errmsg, title);
    //     });
    // },
    onEditItemChange($event, item) {
      // const self = this;
      switch (item) {
        case "pointId":
          this.getPointPeriods($event, this.editItem.pointId);
          this.editItem.periodId = null;
          this.editItem.annualBudgetId = null;
          this.availableBudgets = [];
          break;
        case "periodId":
          this.getAnnualBudgets(
            $event,
            this.editItem.pointId,
            this.editItem.periodId
          );
          this.editItem.annualBudgetId = null;
          break;
        case "organizationId":
          this.getDepartmentList(this.editItem.organizationId);
          // 加權限控管後，若是單位管理員，則機構&單位都不可修改
          this.editItem.annualBudgetId = null;
          this.editItem.departmentId = null;
          break;
        default:
          break;
      }
    },
    onCloseTransferDialog() {
      this.transferFrom = {
        transferPoints: null,
        name: null,
      };
      // this.transferredBudgetId = null;
      this.openTransferDialog = false;
    },
    onBudgetTransferClick(item) {
      // show dialog to select transfer budget
      // this.transferredBudgetId = null;
      if (item.availablePoints <= 0) {
        this.$swal({
          text: "沒有點數可以轉讓",
          type: "warning",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.transferFrom = {
        name: item.budgetName,
        id: item.id,
        availablePoints: item.availablePoints,
        transferPoints: item.availablePoints,
      };
      console.log("transfer:" + JSON.stringify(item));
      // 只能轉同一單位已審核且未結算的預算
      this.availableTransferBudgets = this.tableData.filter(
        (i) =>
          i.id !== item.id &&
          i.departmentId === item.departmentId &&
          i.closeStatus === 0 &&
          i.reviewStatus === 1
      );
      this.openTransferDialog = true;
    },
    onTransferredDone() {
      this.getDepartmentBudgets();
      this.onCloseTransferDialog();
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
